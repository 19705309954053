import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import instance from "../../Api";
import { useAuth } from "../../AuthContext";
import { useLocation, useNavigate } from "react-router-dom";

const InvoicePage = () => {
  const { smuser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(isMobileDevice());
  const billData = location.state ? location.state.billData : null;
  const [htmlContent, setHtmlContent] = useState("");
  const [pdfUrl, setPdfUrl] = useState(null);
  const htmlContentRef = useRef(null);
  // console.log(billData);
  function isMobileDevice() {
    return window.matchMedia("(max-width: 800px)").matches;
  }

  useEffect(() => {
    function handleResize() {
      setIsMobile(isMobileDevice());
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);
  useEffect(() => {
    const fetchHtmlContent = async () => {
      try {
        const payload = {
          user_id: smuser.id,
          id: billData.id,
        };
        const response = await instance.post("/invoiceview", payload);
        // console.log(response.data, "response.data");
        setHtmlContent(response.data);
      } catch (error) {
        console.error("Error fetching HTML content:", error);
      }
    };
    if (billData === null) {
      navigate(-1);
    } else {
      fetchHtmlContent();
    }
  }, []);

  // const generatePdf = async () => {
  //   if (htmlContentRef.current) {
  //     const canvas = await html2canvas(htmlContentRef.current);
  //     const imgData = canvas.toDataURL("image/png");

  //     const pdf = new jsPDF("p", "mm", "a4");
  //     const imgProps = pdf.getImageProperties(imgData);
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

  //     pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
  //     const pdfBlob = pdf.output("blob");
  //     const pdfUrl = URL.createObjectURL(pdfBlob);
  //     setPdfUrl(pdfUrl);
  //     downloadPdf(pdfUrl);
  //   }
  // };

  const generatePdf = async () => {
    if (htmlContentRef.current) {
      try {
        // Ensure all images are loaded before capturing the canvas
        const images = htmlContentRef.current.getElementsByTagName("img");
        await Promise.all(
          Array.from(images).map((img) => {
            if (img.complete) return Promise.resolve();
            return new Promise((resolve, reject) => {
              img.onload = resolve;
              img.onerror = reject;
            });
          })
        );

        const canvas = await html2canvas(htmlContentRef.current);
        const imgData = canvas.toDataURL("image/png");

        const pdf = new jsPDF("p", "mm", "a4");
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        const pdfBlob = pdf.output("blob");
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl);
        downloadPdf(pdfUrl);
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    }
  };

  const downloadPdf = (pdfUrl) => {
    if (pdfUrl) {
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download =
        billData.orderid !== null
          ? `${billData.orderid}_invoice.pdf`
          : "file.pdf";
      link.click();
    }
  };

  const printPdf = async () => {
    if (htmlContentRef.current) {
      const canvas = await html2canvas(htmlContentRef.current);
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      const pdfBlob = pdf.output("blob");
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const printWindow = window.open(pdfUrl);
      printWindow.onload = () => {
        printWindow.print();
      };
    }
    // console.log(pdfUrl, "pdfUrl");
  };

  return (
    <div style={{ marginLeft: isMobile ? "" : "35rem" }}>
      {/* {pdfUrl && ( */}
      <div>
        <h2>PDF Preview:</h2>
        {/* <iframe src={pdfUrl} width="100%" height="500px"></iframe> */}
        {isMobile ? (
          <div style={{ marginLeft: "15px" }}>
            <button
              className="top-buttons-creact-machine"
              onClick={generatePdf}
              style={{ marginTop: "10px" }}
            >
              Download
            </button>

            <button
              className="top-buttons-creact-machine"
              onClick={printPdf}
              style={{ marginTop: "10px", marginLeft: "15px" }}
            >
              Print
            </button>
          </div>
        ) : (
          <div
            style={{
              position: "absolute",
              right: "15%",
              top: "10%",
            }}
          >
            <button
              className="top-buttons-creact-machine"
              onClick={generatePdf}
              style={{ marginTop: "10px", marginLeft: "150px" }}
            >
              Download
            </button>

            <button
              className="top-buttons-creact-machine"
              onClick={printPdf}
              style={{ marginTop: "10px", marginLeft: "15px" }}
            >
              Print
            </button>
          </div>
        )}

        {/* <button onClick={printPdf}>Print PDF</button> */}
      </div>
      {/* )} */}
      <div
        ref={htmlContentRef}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
        style={{ width: isMobile ? "120mm" : "210mm", padding: "" }}
      />
      {/* <button onClick={generatePdf}>Generate PDF</button> */}
    </div>
  );
};

export default InvoicePage;
